<template>
  <v-navigation-drawer
    v-model="abrirSidebar"
    absolute
    temporary
    style="overflow: hidden"
    width="350">
    <upload-file
      v-if="tipoImportacao"
      name="fornecedor-sidebar-upload"
      :title="$t('title.importar_arquivo')"
      :subtitle="subtitle"
      :id="uploadFileDropzoneId"
      :url="`${importUrl}=2`"
      @UPLOADFILE_SUCCESS="uploadSuccess"
      @UPLOADFILE_ERROR="uploadError"
      :accepted-file-types="tipoImportacao.arquivosSuportados"/>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import UploadFile from '@/shared-components/upload/UploadFile';

import { basePath } from '@/common/resources/importacoes';

export default {
  data() {
    return {
      abrirSidebar: false,
    };
  },
  computed: {
    ...mapGetters('importacao', {
      tiposImportacao: 'getTiposImportacao',
    }),
    tipoImportacao() {
      return this.tiposImportacao.find((el) => el.nome === 'importacao_fornecedor');
    },
    importUrl() {
      return `${basePath}importacao?tipo_importacao`;
    },
    subtitle() {
      return this.$t('subtitle.importar_arquivo', {
        thing: this.$tc('label.produto', 1).toLowerCase(),
      });
    },
    uploadFileDropzoneId() {
      return `myVueDropzone${(Math.random() * Math.random() * 100).toString().replace('.', '')}`;
    },
  },
  components: {
    UploadFile,
  },
  methods: {
    close(ref) {
      console.log(`Closed: ${ref}`); // eslint-disable-line
      this.abrirSidebar = false;
    },
    open(ref) {
      console.log(`Opened: ${ref}`); // eslint-disable-line
      this.abrirSidebar = true;
    },
    toggle() {
      this.$refs.leftSidenav.toggle();
    },
    uploadError(file, msg) {
      this.$toast(msg || this.$t('message.erro_tente_importar_novamente'));
    },
    uploadSuccess() {
      this.$toast(this.$t('message.importado_em_breve_sera_processado'), 'bottom', 8E3);
    },
  },
};
</script>
