<template>
  <div>
    <div class="FornecedorList">
      <v-card class="card-size">
        <v-card-title>
          <h2 class="headline mb-0">{{ $tc('title.fornecedor', 2) }}</h2>
        </v-card-title>

        <fornecedor-sidebar-importacao
          v-if="canAccessCRUD && tipoImportacao"
          ref="sidebar">
        </fornecedor-sidebar-importacao>

        <fornecedor-list-dados :canAccessCRUD="canAccessCRUD"
                                      @TABELA_EXPORTAR_DADOS="requestExportacao"
                                      @TABELA_IMPORTAR_DADOS="$refs.sidebar.open()"
                                      ref="tabelaFornecedor">
        </fornecedor-list-dados>

        <v-card-actions v-if="canAccessCRUD">
          <v-row>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn id="btn_novo" fab @click="novo" class="v-btn__fab" color="primary" v-on="on" bottom right fixed>
                  <v-icon>add</v-icon>
                </v-btn>
              </template>
              <span>{{$t('label.novo')}}</span>
            </v-tooltip>
          </v-row>
        </v-card-actions>

      </v-card>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { generateComputed } from '@/common/functions/roles-computed-generator';
import exportacao from '@/common/functions/exportacao';
import FornecedorListDados from './FornecedorListDados';
import FornecedorSidebarImportacao from './FornecedorSidebarImportacao';

export default {
  name: 'FornecedorList',
  data() {
    return {
      metadadoResource: this.$api.metadado(this.$resource),
    };
  },
  components: {
    FornecedorListDados,
    FornecedorSidebarImportacao,
  },
  computed: {
    ...generateComputed('FORN', [
      'canAccessPage',
      'canAccessCRUD',
    ]),
    ...mapGetters('importacao', {
      tiposImportacao: 'getTiposImportacao',
    }),
    tipoImportacao() {
      return this.tiposImportacao.find((el) => el.nome === 'importacao_fornecedor');
    },
  },
  methods: {
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
    novo() {
      this.$router.push({ name: 'novoFornecedor' });
    },
    requestExportacao(cb) {
      const params = {
        filtro: this.$refs.tabelaFornecedor.getSearchQuery() || '',
      };
      exportacao.exportar(cb, 'fornecedor_varejo', this, params);
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
    }
  },
};
</script>
